.tooltip.cs-tooltip {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-family: $primary-font-family;

  .tooltip-inner {
    background-color: var(--ibk-white);
    color: var(--grises-grey-2);
    border-radius: 4px;
    font-size: 11px;
    padding: 1rem 1.2rem;
    min-width: 150px;
  }

  &.top {
    .tooltip-arrow:before,
    .tooltip-arrow {
      border-top-color: var(--ibk-white);
    }
  }

  &.right {
    margin-left: 3px;

    .tooltip-arrow:before,
    .tooltip-arrow {
      border-right-color: var(--ibk-white);
    }
  }
}

.cs-alert {
  .swal2-popup {
    width: 410px;
  }

  &.swal2-container {
    z-index: 12000;
  }
  &.cs-alert-legacy {
    .swal2-popup {
      width: 32em;
    }
  }

  .swal2-close {
    background-image: url('/assets/images/cerrar.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 16px;
    margin-top: 16px;
    margin-right: 16px;
    padding: 4px;
    transition: all var(--transition-duration);
    overflow: hidden;
    font-size: 0;

    &:hover {
      background-image: url('/assets/images/cerrar.svg');
      filter: brightness(50%);
    }
  }

  .swal2-title {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--ibk-black);
  }

  .swal2-content {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--ibk-black);
  }

  .swal2-actions {
    display: grid;
  }

  .swal2-styled {
    &.swal2-confirm,
    &.swal2-cancel {
      height: 48px;
      width: 207px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      border-radius: 4px;
      transition: all var(--transition-duration);
    }

    &.swal2-confirm {
      color: var(--ibk-white);
      background-color: var(--primary-green-1);
      border: solid 1px var(--primary-green-1);

      &:hover {
        background-color: var(--primary-green-2);
      }
    }

    &.swal2-cancel {
      background-clip: padding-box;
      background-color: var(--ibk-white);
      border: solid 1px var(--ibk-white);
      color: var(--primary-green-1);

      &:hover {
        background-color: var(--ibk-white);
        color: var(--primary-green-1);
        text-decoration: underline;
      }
    }
  }

  .swal2-icon {
    width: 3rem;
    height: 3rem;

    .swal2-icon-content {
      font-size: 2.5rem;
    }
  }
}

.button-outline-green {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 4px;
  background-color: var(--ibk-white);
  border: solid 1px var(--primary-green-1);
  color: var(--primary-green-1);
}

.shepherd-button {
  background: var(--primary-green-1);
  color: var(--ibk-white);

  &:not(:disabled) {
    &:hover {
      background: var(--primary-green-2);
      color: var(--ibk-white);
    }
  }
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target.movements.highlight.movements {
  pointer-events: auto;
}

main.general:not(.animating) {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

@keyframes box1 {
  0% {
    transform: scale(0);
  }

  15% {
    transform: scale(1);
    top: 10px;
  }

  25% {
    top: 30px;
    right: 10px;
    transform: rotate(0);
  }

  30% {
    top: 30px;
    right: 16.666px;
    transform: rotate(-90deg);
  }

  30.01% {
    top: 30px;
    right: 16.666px;
    transform: rotate(0);
  }

  35% {
    top: 30px;
    right: 23.333px;
    transform: rotate(-90deg);
  }

  35.01% {
    top: 30px;
    right: 23.333px;
    transform: rotate(0);
  }

  40% {
    top: 30px;
    right: 30px;
    transform: rotate(-90deg);
  }

  50% {
    top: 20px;
    right: 20px;
    transform: rotate(-90deg);
  }

  100% {
    top: 20px;
    right: 20px;
    transform: rotate(-90deg);
  }
}

@keyframes box2 {
  0% {
    transform: scale(0);
  }

  15% {
    transform: scale(1);
    bottom: 10px;
  }

  25% {
    bottom: 30px;
    left: 10px;
    transform: rotate(0);
  }

  30% {
    bottom: 30px;
    left: 16.666px;
    transform: rotate(-90deg);
  }

  30.01% {
    bottom: 30px;
    left: 16.666px;
    transform: rotate(0);
  }

  35% {
    bottom: 30px;
    left: 23.333px;
    transform: rotate(-90deg);
  }

  35.01% {
    bottom: 30px;
    left: 23.333px;
    transform: rotate(0);
  }

  40% {
    bottom: 30px;
    left: 30px;
    transform: rotate(-90deg);
  }

  50% {
    bottom: 20px;
    left: 20px;
    transform: rotate(-90deg);
  }

  100% {
    bottom: 20px;
    left: 20px;
    transform: rotate(-90deg);
  }
}

@keyframes logo {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  50% {
    transform: translate(-50%, -50%) scale(0);
  }

  65% {
    transform: translate(-50%, -50%) scale(1);
  }

  75% {
    transform: translate(-50%, -50%) scale(0.9);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.9);
  }
}

.a-loader__spinner {
  width: 80px;
  height: 80px;
  text-indent: -9999em;
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  &:after,
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background: #00bb31;
    opacity: 0.7;
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    will-change: transform, top, left, right, bottom;
    z-index: 1;
    animation: box1 1.8s infinite linear;
  }

  &::after {
    top: auto;
    bottom: 10px;
    right: auto;
    left: 10px;
    animation: box2 1.8s infinite linear;
  }
}

.a-loader__logo {
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: logo 1.8s infinite;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgMzIwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMjAgMzIwIj48cGF0aCBkPSJNNTAuNCAxMC45MWMtMjEuOTkgMC0zOS43OCAxNy45MS0zOS43OCA0MC4wMVYzMDloMjU4LjMxYzIyIDAgMzkuODMtMTcuOTEgMzkuODMtNDBsLjA0LTI1OC4xSDUwLjR6IiBzdHlsZT0iZmlsbDojMDBhOTRmIi8+PHBhdGggc3R5bGU9ImZpbGw6I2ZmZiIgZD0iTTIzOS4xMyAyMjYuMDZMODAuMjIgMjU0Ljc4Vjk0LjRsMTU4LjkxLTI4LjcxeiIvPjwvc3ZnPg==);
}

.overlay-app {
  @apply tw-fixed tw-w-full tw-h-screen tw-bg-white tw-top-0 tw-transition-opacity tw-ease-in tw-duration-700;
  &.showed {
    @apply tw-pointer-events-none tw-opacity-0;
  }
}
