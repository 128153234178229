.chk-vertical {
  display: block;
  width: 40px;
  text-align: center;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
}

:-ms-input-placeholder.mat-mdc-input-element {
  color: rgba(0, 0, 0, 0.42);
}

.btn-eureca-green {
  @include font-text(14px !important, 500 !important);
  color: var(--ibk-white) !important;
  background-color: var(--primary-green-1) !important;
  @include border-radius(4px !important);
  border: solid 1px var(--primary-green-1) !important;

  &:hover {
    background-color: var(--primary-green-2) !important;
  }

  &:active {
    background-color: var(--primary-green-1) !important;
    background-image: none !important;
  }

  &.btn-disabled {
    background-color: var(--grises-grey-1) !important;
    border: solid 1px var(--grises-grey-1) !important;
  }
}

.btn-eureca-yellow {
  @include font-text(14px !important, 500 !important);
  color: var(--ibk-black) !important;
  background-color: var(--extended-palette-yellow-3) !important;
  @include border-radius(4px !important);
  border: solid 1px var(--extended-palette-yellow-3) !important;

  &:hover {
    background-color: var(--extended-palette-yellow-4) !important;
  }

  &:active {
    background-color: var(--extended-palette-yellow-3) !important;
    background-image: none !important;
  }

  &.btn-disabled {
    background-color: var(--grises-grey-1) !important;
    border: solid 1px var(--grises-grey-1) !important;
  }
}

.btn-outline-green {
  @include font-text(16px !important, 500 !important);
  @include border-radius(4px !important);
  background-color: var(--ibk-white) !important;
  border: solid 1px var(--ibk-white) !important;
  color: var(--primary-green-1) !important;

  &:hover {
    background-color: var(--grises-grey-4) !important;
  }

  &:active {
    background-color: var(--ibk-white) !important;
    background-image: none !important;
  }

  &.btn-disabled {
    color: var(--grises-grey-1) !important;
    background-color: var(--ibk-white) !important;
  }
}

.btn-eureca-green-alert {
  height: 48px;
  width: 207px;
  @include font-text(14px !important, normal !important);
  color: var(--ibk-white) !important;
  background-color: var(--primary-green-1) !important;
  @include border-radius(4px !important);
  border: solid 1px var(--primary-green-1) !important;

  &:hover {
    background-color: var(--primary-green-2) !important;
  }

  &:active {
    background-color: var(--primary-green-1) !important;
    background-image: none !important;
  }

  &.btn-disabled {
    background-color: var(--grises-grey-1) !important;
    border: solid 1px var(--grises-grey-1) !important;
  }
}

.btn-outline-green-alert {
  height: 48px;
  width: 207px;
  @include font-text(14px !important, normal !important);
  @include border-radius(4px !important);
  background-color: var(--ibk-white) !important;
  border: solid 1px var(--ibk-white) !important;
  color: var(--primary-green-1) !important;

  &:hover {
    background-color: var(--ibk-white) !important;
    color: var(--primary-green-1);
    text-decoration: underline;
    cursor: pointer;
  }

  &:active {
    background-color: var(--ibk-white) !important;
    background-image: none !important;
  }

  &.btn-disabled {
    color: var(--primary-green-1);
    text-decoration: underline;
    cursor: pointer;
  }
}
