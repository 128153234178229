@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin border-radius($radius) {
  @include css3-prefix('border-radius', $radius);
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

@mixin box-shadows(
  $x: 4px,
  $y: 10px,
  $blur: 0,
  $color: rgba(0, 0, 0, 0.1),
  $inset: 0
) {
  @if ($inset != '') {
    @include css3-prefix('box-shadow', $inset $x $y $blur $color);
  } @else {
    @include css3-prefix('box-shadow', $x $y $blur $color);
  }
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin draw-progress($progress, $color) {
  .pie {
    .left-side {
      transform: rotate($progress * 3.6deg);
    }
    @if $progress <=50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);
      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin font-text($size, $weight: normal) {
  font-family: $primary-font-family !important;
  font-size: $size;
  font-weight: $weight;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

@mixin cuadro($width, $height) {
  width: $width;
  height: $height;
  object-fit: contain;
  border-radius: 4px;
}

@mixin btn-eureca {
  @include font-text(14px, 500);
  color: var(--ibk-white);
  background-color: var(--primary-green-1);
  border-radius: 4px;
  border: solid 1px var(--primary-green-1);
  @include box-shadows(0, 1px, 4px, 0, rgba(252, 154, 0, 0.2));
  &:hover {
    background-color: var(--primary-green-1);
    border-radius: 4px;
  }
}
