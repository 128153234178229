body {
  font-family: $primary-font-family;
  color: var(--ibk-black);
}

a {
  color: var(--primary-green-1);

  &:hover {
    color: var(--primary-green-1);
  }
}

.al-left {
  float: left;
}

.al-right {
  float: right;
}

.clear-float {
  clear: both;
}

.scroll-div::-webkit-scrollbar {
  width: 8px;
}

.scroll-div::-webkit-scrollbar-thumb {
  background-color: var(--ibk-black);
  border-radius: 4px;
}

.scroll-div::-webkit-scrollbar-track {
  background-color: var(--grises-grey-4);
  border-radius: 4px;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.messsage-error {
  @include font-text(12px, 500);
  color: var(--extended-palette-watermelon-1) !important;
}

.w-35 {
  width: 35%;
}

.w-100 {
  width: 100%;
}

.btn-big {
  font-size: 16px;
  padding-left: 45px;
  padding-right: 45px;
  height: 48px;
}

.texto-azul {
  @include font-text(32px, $weight: 500);
  color: var(--secondary-blue-1);
}

.font-black {
  @include font-text(18px, $weight: 600);
  color: var(--ibk-black);
}

.swal2-content {
  @include font-text(16px, $weight: 500);
  color: var(--ibk-black) !important;
  text-align: center;
}

.input-text {
  @include font-text(16px !important, normal !important);
  color: var(--ibk-black) !important;
}

.swal2-styled {
  border-style: none;
  margin: 0.5em 10vh !important;
}

.pts16 {
  margin-top: 16px !important;
}

.pts30 {
  margin-top: 30px !important;
}

.w-70 {
  width: 70%;
}

.w-15 {
  width: 15%;
}

.custom-container {
  width: 100%;
  max-width: 1320px !important;
  margin: 0 auto;
}

.pp-5 {
  padding: 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.ppl-5,
.ppx-5 {
  padding-left: 3rem !important;
  padding-top: 3rem !important;
}

.icon-gb {
  margin-right: 5px;
  top: -2px;
  position: relative;
}

.reponsive-servicio-pago {
  display: flex;
}

.reponsive-servicio-pago1 {
  display: none;
}

.ppr-5 {
  padding-right: 3rem !important;
}

.btn-fn {
  display: block;
}

.btn-fn1 {
  display: none;
}

.br-none-escritorio {
  display: initial;
}

.br-none-mobil {
  display: none;
}

.br-none-tablet {
  display: none;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  transition: all 0.5s;
}

.backdrop-background-opaque {
  background-color: var(--grises-grey-4);
}

.upload-files-dialog .mat-mdc-dialog-actions {
  position: absolute;
  right: calc(50% - 430px);
  top: 5px;
}
