@layer components {
  .skeleton-background {
    @apply tw-relative tw-isolate tw-overflow-hidden before:tw-absolute before:tw-inset-0 before:-tw-translate-x-full before:tw-animate-[shimmer_2s_infinite] before:tw-bg-gradient-to-r before:tw-from-transparent before:tw-via-extended-grey-5 before:tw-to-transparent;
  }

  .modal-custom-cs {
    .p-dialog-header {
      .p-dialog-title {
        @apply tw-text-2xl tw-font-medium lg:tw-pl-6;
      }
    }

    .p-dialog-content {
      @apply lg:tw-px-12;
    }

    &.modal-thin {
      .p-dialog-header {
        @apply tw-py-3 lg:tw-px-4;
        .p-dialog-title {
          @apply tw-text-pretty tw-text-xl lg:tw-pl-0;
        }
      }

      .p-dialog-content {
        @apply lg:tw-px-4;
      }

      .p-dialog-footer {
        @apply tw-pb-2;
      }
    }
  }

  .box-shadow {
    @apply tw-rounded-md tw-border tw-border-extended-grey-4 tw-bg-white tw-shadow-md;
  }

  .ibk-box-rounded {
    @apply tw-rounded-br-3xl tw-rounded-tl-3xl;
  }

  .ibk-badge-menu {
    @apply tw-w-12 tw-text-[0.5rem] tw-font-normal;
  }

  .menu-item-categories {
    .p-menuitem-text {
      @apply tw-flex tw-flex-col;
      .name {
        @apply tw-line-clamp-1 tw-break-all;
      }
      .type {
        @apply tw-mt-2 tw-text-xxs tw-text-secondary-blue-3;
      }
    }
  }

  .onboarding-step {
    &.shepherd-has-title .shepherd-content .shepherd-header {
      @apply tw-bg-transparent tw-pb-1 tw-text-xs;

      .shepherd-title {
        @apply tw-text-xs tw-text-extended-grey-1;
      }
    }

    &.shepherd-element {
      max-width: 358px;
      &.records {
        max-width: none;
      }
    }

    .shepherd-text {
      @apply tw-p-4;
    }

    .shepherd-footer {
      @apply tw-justify-between tw-p-4 tw-pt-3;

      .shepherd-button {
        @apply tw-w-[120px] tw-px-5 tw-py-2 tw-text-sm;
        &.btn-primary {
          @apply tw-bg-primary-green-1 tw-text-white hover:tw-bg-primary-green-2;
        }
        &.btn-outline-primary {
          @apply tw-border tw-border-solid tw-border-primary-green-1 tw-bg-white tw-text-primary-green-1;
        }
      }
    }
  }
}
