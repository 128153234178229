@import '_variables.scss';
@import '_mixin.scss';

@layer tailwind-base {
  @tailwind base;
}

@layer custom {
  @import 'forms';
}

@layer general {
  @import 'configuration';
  @import 'fonts';
  @import 'material-custom';
  @import 'main';
  @import 'reponsive';
}

@layer tailwind-utilities {
  @tailwind components;
  @import 'components';
  @tailwind utilities;
}

@import 'global';
