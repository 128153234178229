@media screen and (min-width: 2000px) {
  #btn-regresar {
    display: none !important;
  }
}

@media screen and (max-width: 2000px) {
  #btn-regresar {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .btn-outline-green {
    width: 100% !important;
  }
  .btn-col-1 {
    padding-left: 0 !important;
    width: 100%;
    text-align: center;
  }
  .input-text {
    margin-top: 0.3em !important;
  }
  .formulario {
    width: 100% !important;
  }
  .cont-login {
    width: 450px !important;
    padding: 0 4em !important;
    margin: auto !important;
  }
  .btn-login-ingresar {
    width: 100% !important;
  }
  .title-pago {
    font-size: 32px !important;
  }
  .text-footer {
    font-size: 12px !important;
  }
  #cont-password {
    justify-content: center !important;
  }
  #cont-empresa-editar {
    width: 100% !important;
    padding: 2em 9em !important;
    padding-left: 9rem !important;
  }
  .btn-rp-password {
    margin-bottom: 1.5em !important;
  }
}

@media screen and (max-width: 1000px) {
  .title-login {
    padding-bottom: 1em;
    margin-left: 0 !important;
  }
  .btn-fn {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  #btn-regresar {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  #justify-reponsive {
    justify-content: center !important;
  }
  .title-landing {
    margin-left: 0 !important;
  }
  .btn-eureca-green {
    width: 192px !important;
    height: 48px !important;
  }
  .cont-login {
    padding: 0 2em !important;
    width: 60% !important;
  }
  .ppt-5 {
    padding-top: 1.5rem !important;
  }
  .pp-5 {
    padding: 1.5rem !important;
  }
  .formulario {
    width: 100% !important;
    margin: auto;
  }
  .form {
    width: 100% !important;
  }
  .ppr-5 {
    padding-right: 1.5rem !important;
  }
  .text-footer {
    font-size: 12px !important;
    text-align: center !important;
  }
  #cont-empresa-editar {
    padding: 2em 2em !important;
    padding-left: 2rem !important;
  }
  .cuadro {
    width: 656px !important;
    height: 505px !important;
    padding-right: 52px !important;
    padding-left: 52px !important;
  }
  .block-right {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 780px) {
  .cont-login {
    width: 70% !important;
  }
}

@media screen and (max-width: 700px) {
  .cont-login {
    width: 80% !important;
    padding: 1.5em 2em !important;
  }
}

@media screen and (max-width: 650px) {
  .none-footer {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .title-pago {
    font-size: 22px !important;
    line-height: 1 !important;
    text-align: center !important;
    -webkit-text-stroke: 0 var(--ibk-white) !important;
  }
  .container {
    padding: 0 2em !important;
  }
  .title-login {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 570px) {
  .descripcion {
    font-size: 12px !important;
    text-align: center !important;
  }
  .label-text-ruc {
    font-size: 16px !important;
  }
  .img-login {
    margin-left: 0 !important;
    width: 134px !important;
    height: 40px !important;
    padding: 0 0 0 0 !important;
  }
  #text-olv {
    padding: 0 !important;
  }
  #idCerrar {
    position: absolute;
  }
  .sub-el {
    text-align: center;
  }
  .cont-login {
    width: 100% !important;
  }
  .text-titulo {
    font-size: 16px !important;
  }
  .text-green-d {
    font-size: 16px !important;
  }
  .br-none-mobil {
    display: block !important;
  }
  .img-information {
    margin: 10px 4px auto 2px !important;
  }
  .text-message-name {
    font-size: 12px !important;
    line-height: 1.25 !important;
    margin: 7px 1px auto 0 !important;
  }
  small {
    font-size: 12px !important;
  }
  .btn-eureca-green {
    margin-top: 5px !important;
  }
  .cuadro {
    width: 272px !important;
    height: 440px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 18px !important;
    flex-direction: column !important;
  }
}
