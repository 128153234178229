:root {
  --font-family: 'Geometria', 'Helvetica Neue', sans-serif;
}

.p-inputtext {
  font-family: 'Geometria', 'Helvetica Neue', sans-serif;
  padding: 0.429rem;
}

.p-inputgroup-addon {
  padding: 0.429rem 0.75rem;
}

.p-terminal .p-terminal-input {
  font-family: 'Geometria', 'Helvetica Neue', sans-serif;
}

a {
  color: var(--primary-green-1);
  transition: color 0.4s;

  &:hover {
    color: var(--primary-green-2);
  }
}

.p-component {
  font-family: 'Geometria', 'Helvetica Neue', sans-serif;
}

.p-dropdown {
  .p-dropdown-label.p-placeholder {
    color: var(--grises-grey-1);
  }

  .p-dropdown-trigger {
    color: var(--grises-grey-1);

    .p-dropdown-trigger-icon {
      font-size: 0.5rem;
      transition: all 0.5s;
    }
  }

  &.p-dropdown-open {
    .p-dropdown-trigger .p-dropdown-trigger-icon {
      transform: rotate(180deg);
    }
  }

  &:not(.p-disabled) {
    &:hover {
      border-color: var(--primary-green-1);
    }

    &.p-focus {
      border-color: var(--primary-green-1);
      box-shadow: 0 0 0 0.2rem var(--primary-green-5);
    }

    .p-dropdown-trigger {
      color: var(--primary-green-1);
    }
  }

  &.p-disabled {
    opacity: 1;
    background-color: #e2e8f0;
    color: #64748b;
  }
}

.p-multiselect {
  .p-multiselect-label {
    padding: 0.429rem;
  }

  .p-multiselect-trigger {
    color: var(--primary-green-1);

    .p-multiselect-trigger-icon {
      font-size: 0.5rem;
      transition: all 0.5s;
    }
  }

  &.p-multiselect-open {
    .p-multiselect-trigger .p-multiselect-trigger-icon {
      transform: rotate(180deg);
    }
  }

  &:not(.p-disabled).p-focus {
    border-color: var(--primary-green-1);
    box-shadow: 0 0 0 0.2rem var(--primary-green-5);
  }
}

.p-checkbox {
  .p-checkbox-box {
    transition: all 0.5s;

    &.p-focus {
      border-color: var(--primary-green-1);
      box-shadow: 0 0 0 0.2em var(--primary-green-4);
    }

    &.p-highlight {
      border-color: var(--primary-green-1);
      background-color: var(--primary-green-1);

      &:not(.p-disabled):hover {
        border-color: var(--primary-green-4);
        background-color: var(--primary-green-4);
      }

      &.p-focus {
        border-color: var(--primary-green-1);
        background-color: var(--primary-green-1);
        color: var(--ibk-white);
        box-shadow: 0 0 0 0.2em var(--primary-green-5);
      }
    }

    .p-checkbox-icon {
      font-size: 13px;
      font-weight: bold;
      line-height: 19px;
      transition: all 0.2s;
    }
  }

  &:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: var(--primary-green-1);
    box-shadow: 0 0 0 0.2em var(--primary-green-4);
  }
}

.p-radiobutton {
  .p-radiobutton-box.p-highlight {
    border-color: var(--primary-green-1);
    background-color: var(--ibk-white);

    &:not(.p-disabled):hover {
      border-color: var(--primary-green-4);
      background-color: var(--ibk-white);
    }

    &.p-focus {
      border-color: var(--primary-green-1);
      background-color: var(--ibk-white);
      box-shadow: 0 0 0 0.2em var(--primary-green-5);
    }

    .p-radiobutton-icon {
      background-color: var(--primary-green-1);
    }
  }
}

.p-inputtext {
  font-size: 16px;
  color: var(--ibk-black);

  &.p-inputtext-sm {
    font-size: 14px;
  }

  &:disabled {
    opacity: 1;
    background-color: #e2e8f0;
    color: #64748b;
  }

  &:enabled {
    &:hover {
      border-color: var(--primary-green-1);
    }

    &:focus {
      border-color: var(--primary-green-1);
      box-shadow: 0 0 0 0.2rem var(--primary-green-5);

      &:not(.p-state-error) {
        border-color: var(--primary-green-1);
        box-shadow: 0 0 0 0.2rem var(--primary-green-5);
      }
    }
  }

  &.ng-dirty.ng-invalid {
    border-color: var(--extended-palette-watermelon-1);
  }

  &::placeholder {
    color: var(--grises-grey-1);
  }
}

.p-steps {
  ul {
    display: flex;
    justify-content: space-between;
  }

  .p-steps-item {
    &:before {
      margin-top: auto;
    }

    &.p-highlight {
      .p-menuitem-link {
        .p-steps-number {
          background-color: var(--primary-green-1);
          color: var(--ibk-white);
        }
      }
    }

    .p-menuitem-link {
      flex-direction: row;
      padding: 0;
      border-radius: 50%;

      .p-steps-title {
        display: none;
      }

      .p-steps-number {
        color: var(--primary-green-1);
        background-color: var(--ibk-white);
        border: 1px solid var(--primary-green-1);
        top: 0;
        margin-bottom: 0;
        transition: all 0.5s;
      }
    }

    &.p-disabled,
    &.p-widget:disabled {
      opacity: 1;

      .p-menuitem-link {
        .p-steps-number {
          color: var(--primary-green-3);
          border: 1px solid var(--primary-green-3);
        }
      }
    }
  }
}

.p-sidebar {
  &.edit-form {
    @apply lg:tw-border-none lg:tw-p-0;

    .p-sidebar-close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 20;
    }
  }
}

.p-button {
  border-radius: 4px;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s,
    color 0.2s;
  color: var(--ibk-white);
  background: var(--primary-green-1);
  border: 1px solid var(--primary-green-1);

  &.p-splitbutton-defaultbutton {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none;
  }

  &.p-button-danger {
    color: #ffffff;
    background: var(--extended-palette-watermelon-1);
    border: 1px solid var(--extended-palette-watermelon-1);
  }

  &.p-button-text {
    color: var(--primary-green-1);
    background-color: transparent;
    border-color: transparent;

    &:enabled:hover {
      color: var(--primary-green-2);
      background-color: transparent;
      border-color: transparent;
    }
  }

  &.p-button-xs {
    font-size: 0.75rem;
    padding: 0.55625rem 0.89375rem;

    & .p-button-icon {
      font-size: 0.75rem;
    }

    &.p-button-icon-only {
      width: 2rem;
      padding: 0.25rem 0;
    }
  }

  &.p-button-icon-only {
    gap: 0;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: var(--primary-green-1);
    border: 1px solid;

    &:enabled:hover {
      color: var(--ibk-white);
      background: var(--primary-green-1);
      border: 1px solid var(--primary-green-1);
    }
  }

  &:enabled {
    &:hover,
    &:active {
      background-color: var(--primary-green-2);
      color: var(--ibk-white);
      border-color: var(--primary-green-2);
    }
  }

  &.p-button-secondary,
  &.p-button-secondary > .p-button {
    color: var(--ibk-white);
    background: var(--secondary-blue-1);
    border: 1px solid var(--secondary-blue-1);
  }

  &.p-button-third,
  &.p-button-third > .p-button {
    color: var(--ibk-white);
    background: var(--extended-palette-watermelon-1);
    border: 1px solid var(--extended-palette-watermelon-1);

    &:enabled {
      &:hover,
      &:active {
        background-color: var(--extended-palette-watermelon-2);
        color: var(--ibk-white);
        border-color: var(--extended-palette-watermelon-2);
      }
    }
  }

  &.p-button-white,
  &.p-button-white > .p-button {
    color: var(--primary-green-1);
    background: var(--ibk-white);
    border: 1px solid var(--ibk-white);

    &:enabled {
      &:hover,
      &:active {
        color: var(--primary-green-1);
        background-color: var(--grises-grey-4);
        border-color: var(--grises-grey-4);
      }
    }
  }

  &:disabled {
    //opacity: 1;
    //color: var(--grises-grey-2);
    //border-color: var(--grises-grey-2);
  }

  &.input-button {
    font-size: 10px;
    padding: 0.2rem;
  }
}

.p-button-label {
  font-weight: 400;
}

.p-dropdown-panel {
  .p-dropdown-items {
    padding: 0;

    .p-dropdown-item {
      white-space: normal;
      padding: 0.429rem 0.857rem;
    }

    .p-dropdown-item-group.p-highlight,
    .p-dropdown-item.p-highlight {
      color: var(--ibk-white);
      background-color: var(--primary-green-1);
    }

    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
    .p-dropdown-item-group:not(.p-highlight):not(.p-disabled):hover {
      color: var(--ibk-black);
      background-color: var(--grises-grey-4);
    }
  }
}

.p-datepicker-group-container {
  font-size: 14px;

  table {
    font-size: 14px;

    th,
    td {
      padding: 0.25rem;
    }

    td > span {
      width: 2rem;
      height: 2rem;
    }
  }

  .p-link {
    font-size: 14px;
  }

  .p-datepicker-calendar {
    thead th {
      //border-bottom-style: none;
    }

    td {
      //border-top-style: none;
    }
  }
}

.p-datepicker table td > span.p-highlight {
  background-color: var(--primary-green-1);
}

.p-datatable {
  .p-paginator {
    font-size: 12px;
    background-color: var(--ibk-white);
    border-style: none;
    text-align: right;
    padding-right: 20px;
    padding-top: 5px;

    .p-paginator-pages .p-paginator-page.p-highlight {
      background-color: transparent;
      color: var(--primary-green-1);
    }
  }

  table {
    font-size: 14px;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: var(--secondary-blue-1);
      font-size: 12px;
    }

    &.p-highlight {
      background-color: var(--ibk-white);
      color: var(--secondary-blue-1);

      &:hover {
        background-color: var(--primary-green-1);
      }

      .p-sortable-column-icon {
        color: var(--secondary-blue-1);
      }
    }
  }

  .p-datatable-thead > tr {
    color: var(--secondary-blue-1);

    > th {
      border-color: var(--grises-grey-4);
      vertical-align: middle;
      background-color: var(--ibk-white);
      color: var(--secondary-blue-1);

      &:not(:first-child) {
        border-left-style: none;
      }

      &:not(:last-child) {
        border-right-style: none;
      }
    }
  }

  .p-datatable-tbody > tr {
    color: var(--ibk-black);

    &:focus {
      outline-color: transparent;
    }

    &:focus-visible {
      outline-color: transparent;
    }

    &.p-highlight {
      color: var(--ibk-black);
      background-color: var(--ibk-white);
    }

    &:nth-child(even) {
      background-color: var(--ibk-white);

      &.p-highlight {
        color: var(--ibk-black);
        background-color: var(--ibk-white);
      }
    }

    > td {
      border-color: var(--grises-grey-4);
      vertical-align: top;

      &:not(:first-child) {
        border-left-style: none;
      }

      &:not(:last-child) {
        border-right-style: none;
      }

      &:last-child {
        border-left-style: solid;
      }

      .p-inputtext {
        font-size: 14px;
        width: 100%;
        padding: 3px;
      }

      .p-datepicker:not(.p-datepicker-inline) {
        min-width: 310px;
      }
    }
  }
}

.p-inputnumber {
  display: block;

  .p-inputnumber-input {
    width: 100%;
  }
}

.p-calendar {
  &.p-calendar-w-btn .p-datepicker-trigger {
    background: transparent;
    color: var(--primary-green-1);
    padding: 0.429rem;
  }

  .p-datepicker-icon {
    color: var(--primary-green-1);
  }
}

.p-tooltip {
  .p-tooltip-text {
    font-size: 12px;
  }
}

.p-tieredmenu {
  .p-menuitem-link {
    color: var(--primary-green-1);
    font-size: 14px;

    .p-menuitem-text {
      color: var(--primary-green-1);
    }

    .p-menuitem-icon {
      color: var(--primary-green-1);
    }

    .p-submenu-icon {
      color: var(--primary-green-1);
    }

    &:not(.p-disabled) {
      &:hover {
        background: var(--primary-green-5);

        .p-menuitem-text {
          color: var(--grises-grey-1);
        }

        .p-menuitem-icon {
          color: var(--grises-grey-2);
        }

        .p-submenu-icon {
          color: var(--grises-grey-2);
        }
      }
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
  }

  .p-menuitem.p-menuitem-active {
    > .p-menuitem-link {
      background: #eff6ff;

      .p-menuitem-text {
        color: var(--primary-green-2);
      }

      .p-menuitem-icon {
        color: var(--primary-green-2);
      }

      .p-submenu-icon {
        color: var(--primary-green-2);
      }
    }
  }

  .p-menuitem-badge {
    background-color: var(--secondary-blue-2);
    min-width: 48px;
    @apply tw-w-12 tw-grow tw-text-[0.5rem] tw-font-normal;
  }
}

.p-accordion {
  p-accordiontab .p-accordion-tab {
    @apply tw-mb-4 tw-shadow-md;

    &:not(.p-accordion-tab-active) {
      .p-toggleable-content {
        height: 0;
      }
    }
  }

  .p-accordion-header {
    .p-accordion-header-link {
      background: var(--ibk-white);
      color: var(--ibk-black);
      font-weight: 400;
      @apply tw-border-extended-grey-4 tw-px-6;

      &:hover {
        text-decoration: none;
      }
    }

    &:not(.p-disabled) {
      .p-accordion-header-link:focus {
        box-shadow: none;
      }

      &.p-highlight {
        .p-accordion-header-link {
          @apply tw-border-b-0 tw-border-extended-grey-4 tw-bg-white tw-text-secondary-blue-1;
        }

        &:hover .p-accordion-header-link {
          @apply tw-border-b-0 tw-border-extended-grey-4 tw-bg-white tw-text-secondary-blue-1;
        }
      }
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      .p-accordion-header-link {
        background: white;
        border-color: transparent;
      }
    }
  }

  .p-accordion-content {
    @apply tw-border-t-0 tw-border-extended-grey-4 tw-px-6 tw-py-4;
  }

  &.p-accordion-outline {
    p-accordiontab {
      .p-accordion-tab {
        box-shadow: none;
      }
    }

    .p-accordion-header {
      .p-accordion-header-link {
        @apply tw-border-white tw-bg-transparent;
      }

      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-accordion-header-link {
              @apply tw-border-white tw-bg-transparent;
            }
          }
        }
      }

      &:not(.p-disabled).p-highlight {
        &:hover {
          .p-accordion-header-link {
            @apply tw-border-white tw-bg-transparent;
          }
        }

        .p-accordion-header-link {
          @apply tw-border-white tw-bg-transparent;
        }
      }
    }

    .p-accordion-content {
      @apply tw-border-white tw-bg-transparent;
    }
  }
}

.p-carousel {
  .p-carousel-indicators .p-carousel-indicator {
    button {
      width: 1rem;
      height: 1rem;
      margin: 7px;
      border-radius: 50%;

      &.extended-grey-3 {
        background: var(--grises-grey-3);
      }
    }

    &.p-highlight button {
      background: var(--primary-green-1);
      color: var(--ibk-white);

      &.secondary-blue-1 {
        background: var(--secondary-blue-1);
      }
    }
  }
}

.ui-message {
  display: flex;
  gap: 10px;
  font-size: 12px;
  border-radius: 20px;
  padding-left: 10px;
}

.p-overlay-badge {
  .p-badge:not(.p-badge-dot) {
    @apply tw-right-[0.2rem] tw-top-[0.2rem] tw-flex tw-h-[1.2rem] tw-min-w-[1.2rem] tw-items-center tw-justify-center tw-p-0 tw-text-[0.55rem] tw-leading-[1.2rem];
  }
}

.p-badge.p-badge-danger {
  @apply tw-bg-extended-watermelon-1;
}

.p-chip {
  &.p-chip-primary {
    @apply tw-bg-primary-green-1 tw-text-white;
  }
}

.p-fileupload {
  &.upload-excel {
    .p-fileupload-row div:first-child {
      display: none;
    }

    .p-fileupload-row > div {
      width: auto;
    }
  }

  &.with-files {
    .p-fileupload-buttonbar {
      display: none;
    }
  }

  .p-fileupload-choose {
    .p-icon-wrapper {
      @apply tw-mr-2;
    }
  }

  .p-fileupload-content {
    //min-height: 148px;
    padding: 0 1rem;
    transition: all 0.3s;
  }

  &-choose:not(.p-disabled):hover {
    color: var(--ibk-white);
    background: var(--primary-green-2);
    border: 1px solid var(--primary-green-2);
  }
}

.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-icon-field-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-dialog {
  &.simple-dialog {
    .p-dialog-header {
      padding: 0.25rem;
    }

    .p-dialog-footer {
      padding: 0.25rem;
    }
  }
}
.progress-spinner {
  .p-progress-spinner-circle {
    stroke: var(--ibk-black);
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
  }
}
