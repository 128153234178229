@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-Regular.eot');
  src: local('Geometria Regular'), local('Geometria-Regular'),
    url('/assets/fonts/Geometria-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-Regular.woff2') format('woff2'),
    url('/assets/fonts/Geometria-Regular.woff') format('woff'),
    url('/assets/fonts/Geometria-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-Italic.eot');
  src: local('Geometria Italic'), local('Geometria-Italic'),
    url('/assets/fonts/Geometria-Italic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Geometria-Italic.woff2') format('woff2'),
    url('/assets/fonts/Geometria-Italic.woff') format('woff'),
    url('/assets/fonts/Geometria-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-ExtraBoldItalic.eot');
  src: local('Geometria ExtraBold Italic'), local('Geometria-ExtraBoldItalic'),
    url('/assets/fonts/Geometria-ExtraBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-ExtraBoldItalic.woff2') format('woff2'),
    url('/assets/fonts/Geometria-ExtraBoldItalic.woff') format('woff'),
    url('/assets/fonts/Geometria-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-ExtraLightItalic.eot');
  src: local('Geometria ExtraLight Italic'), local('Geometria-ExtraLightItalic'),
    url('/assets/fonts/Geometria-ExtraLightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-ExtraLightItalic.woff2') format('woff2'),
    url('/assets/fonts/Geometria-ExtraLightItalic.woff') format('woff'),
    url('/assets/fonts/Geometria-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-Medium.eot');
  src: local('Geometria Medium'), local('Geometria-Medium'),
    url('/assets/fonts/Geometria-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Geometria-Medium.woff2') format('woff2'),
    url('/assets/fonts/Geometria-Medium.woff') format('woff'),
    url('/assets/fonts/Geometria-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-Light.eot');
  src: local('Geometria Light'), local('Geometria-Light'),
    url('/assets/fonts/Geometria-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Geometria-Light.woff2') format('woff2'),
    url('/assets/fonts/Geometria-Light.woff') format('woff'),
    url('/assets/fonts/Geometria-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-HeavyItalic.eot');
  src: local('Geometria Heavy Italic'), local('Geometria-HeavyItalic'),
    url('/assets/fonts/Geometria-HeavyItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-HeavyItalic.woff2') format('woff2'),
    url('/assets/fonts/Geometria-HeavyItalic.woff') format('woff'),
    url('/assets/fonts/Geometria-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-BoldItalic.eot');
  src: local('Geometria Bold Italic'), local('Geometria-BoldItalic'),
    url('/assets/fonts/Geometria-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/Geometria-BoldItalic.woff') format('woff'),
    url('/assets/fonts/Geometria-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-Bold.eot');
  src: local('Geometria Bold'), local('Geometria-Bold'),
    url('/assets/fonts/Geometria-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Geometria-Bold.woff2') format('woff2'),
    url('/assets/fonts/Geometria-Bold.woff') format('woff'),
    url('/assets/fonts/Geometria-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-LightItalic.eot');
  src: local('Geometria Light Italic'), local('Geometria-LightItalic'),
    url('/assets/fonts/Geometria-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/Geometria-LightItalic.woff') format('woff'),
    url('/assets/fonts/Geometria-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-ExtraBold.eot');
  src: local('Geometria ExtraBold'), local('Geometria-ExtraBold'),
    url('/assets/fonts/Geometria-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-ExtraBold.woff2') format('woff2'),
    url('/assets/fonts/Geometria-ExtraBold.woff') format('woff'),
    url('/assets/fonts/Geometria-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-MediumItalic.eot');
  src: local('Geometria Medium Italic'), local('Geometria-MediumItalic'),
    url('/assets/fonts/Geometria-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/Geometria-MediumItalic.woff') format('woff'),
    url('/assets/fonts/Geometria-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-ExtraLight.eot');
  src: local('Geometria ExtraLight'), local('Geometria-ExtraLight'),
    url('/assets/fonts/Geometria-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-ExtraLight.woff2') format('woff2'),
    url('/assets/fonts/Geometria-ExtraLight.woff') format('woff'),
    url('/assets/fonts/Geometria-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-Heavy.eot');
  src: local('Geometria Heavy'), local('Geometria-Heavy'),
    url('/assets/fonts/Geometria-Heavy.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Geometria-Heavy.woff2') format('woff2'),
    url('/assets/fonts/Geometria-Heavy.woff') format('woff'),
    url('/assets/fonts/Geometria-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-ThinItalic.eot');
  src: local('Geometria Thin Italic'), local('Geometria-ThinItalic'),
    url('/assets/fonts/Geometria-ThinItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Geometria-ThinItalic.woff2') format('woff2'),
    url('/assets/fonts/Geometria-ThinItalic.woff') format('woff'),
    url('/assets/fonts/Geometria-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('/assets/fonts/Geometria-Thin.eot');
  src: local('Geometria Thin'), local('Geometria-Thin'),
    url('/assets/fonts/Geometria-Thin.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Geometria-Thin.woff2') format('woff2'),
    url('/assets/fonts/Geometria-Thin.woff') format('woff'),
    url('/assets/fonts/Geometria-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/iconos.eot?ijqtk6');
  src: url('/assets/fonts/iconos.eot?ijqtk6#iefix') format('embedded-opentype'),
    url('/assets/fonts/iconos.ttf?ijqtk6') format('truetype'),
    url('/assets/fonts/iconos.woff?ijqtk6') format('woff'),
    url('/assets/fonts/iconos.svg?ijqtk6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
